

























import { MatchQuality } from "@/models/case-maintenance";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MatchQualitySelectorComponent extends Vue {
  @Prop() value!: MatchQuality;
  @Prop() heldDownButtonValue!: MatchQuality | null;

  BUTTONS = [MatchQuality.Good, MatchQuality.Neutral, MatchQuality.Bad];

  mouseDown = false;

  mouseLeave() {
    if (this.heldDownButtonValue !== null) {
      this.onInput(this.heldDownButtonValue);
    }
  }

  onMouseDown(t: MatchQuality) {
    this.$emit("button-mouse-down", t);
  }
  onMouseUp(t: MatchQuality) {
    this.$emit("button-mouse-up", t);
  }

  VIEW_DATA = {
    [MatchQuality.Good]: {
      icon: "mdi-emoticon-happy",
      displayName: "Good",
      color: "green"
    },
    [MatchQuality.Bad]: {
      icon: "mdi-emoticon-sad",
      displayName: "Bad",
      color: "red"
    },
    [MatchQuality.Neutral]: {
      icon: "mdi-emoticon-neutral",
      displayName: "Neutral",
      color: "grey"
    }
  };
  onInput(x: MatchQuality) {
    this.$emit("input", x);
  }
}
