

























































































import {
  ExtendedMatchFeedbackRequest,
  ExtendedMatchFeedbackView,
  MatchQuality
} from "@/models/case-maintenance";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { TableHeader } from "../layout/models/table";
import { HighlightTextToken } from "@/models/case-maintenance.d";
import Highlight from "@/components/common/highlight-matches.vue";

import config from "@/config";
import { Filter, FilterField } from "@/models/filters";
import TableFilter from "@/components/table-filters/table-filter.vue";
import MatchQualitySelector from "./match-quality-selector.vue";

@Component({
  components: {
    TableFilter,
    Highlight,
    MatchQualitySelector
  },
  inheritAttrs: false
})
export default class MatchTable extends Vue {
  @Prop({ default: () => [] }) items!: ExtendedMatchFeedbackView[];
  @Prop({ default: 0 }) serverItemsLength!: number;
  @Prop({ default: false }) loading!: boolean;
  @PropSync("page", { default: 1 }) syncedPage!: number;
  @PropSync("itemsPerPage", { default: () => config.defaultPageSize })
  syncedItemsPerPage!: number;
  @PropSync("filters", { required: true }) syncedFilters!: Filter[];
  @Prop({ required: true }) filterFields!: FilterField[];
  @PropSync("matches", { required: true }) matchesRecord!: Record<
    string,
    { score: MatchQuality; comments: string }
  >;

  selectedIds: ExtendedMatchFeedbackView[] = [];

  heldDownButtonValue: null | MatchQuality = null;

  onButtonMouseDown(q: MatchQuality) {
    this.heldDownButtonValue = q;
  }
  onButtonMouseUp() {
    this.heldDownButtonValue = null;
  }

  getMatchValue(id: string) {
    return this.matchesRecord[id]?.score ?? null;
  }
  setMatchValue(id: string, value: MatchQuality) {
    const record = this.matchesRecord[id];
    if (record) {
      record.score = value;
    } else {
      this.$set(this.matchesRecord, id, { score: value, comments: "" });
    }
  }

  getMatchComments(id: string) {
    return this.matchesRecord[id]?.comments ?? "";
  }
  setMatchComments(id: string, comments: string) {
    const record = this.matchesRecord[id];
    if (record) {
      record.comments = comments;
    } else {
      this.$set(this.matchesRecord, id, { comments: comments, score: MatchQuality.Neutral });
    }
  }

  getTokens(textSample: string, matchText: string): HighlightTextToken[] {
    const tokens: HighlightTextToken[] = [];

    const SPLIT_BY_TEXT_RETAIN_DELIMITER = new RegExp(`\\b(?=${matchText})|(?<=${matchText})\\b`);

    const splitText = textSample.split(SPLIT_BY_TEXT_RETAIN_DELIMITER);

    splitText.forEach(x => {
      tokens.push({
        text: x,
        isHighlighted: x == matchText ? true : false,
        isHighlightedSingle: false
      } as HighlightTextToken);
    });

    return tokens;
  }

  highlightMatchesInExtendedText(extendedText: string, matchText: string): HighlightTextToken[] {
    return this.getTokens(extendedText, matchText);
  }

  get numberOfPages() {
    if (this.syncedItemsPerPage === 0) return 1;
    return Math.max(1, Math.ceil(this.serverItemsLength / this.syncedItemsPerPage));
  }

  rowNumber(index: number) {
    if (this.serverItemsLength == 0) return 0;

    return (this.syncedPage - 1) * this.syncedItemsPerPage + index + 1;
  }

  headers: TableHeader[] = [
    {
      text: "Match",
      value: "extendedText",
      sortable: false,

      width: "1px"
    },
    {
      text: "Library Name",
      value: "libraryName",
      sortable: false,
      width: "1px",
      align: "start"
    },
    {
      text: "Rule Name",
      value: "ruleName",
      sortable: false,
      width: "1px"
    },
    {
      text: "Match Quality",
      value: "quality",
      align: "center",
      sortable: false,
      width: "1px"
    },

    {
      text: "Extra Info",
      value: "comments",
      sortable: false,
      width: "1px"
    }
  ];
}
