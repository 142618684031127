

























import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import {
  ExtendedMatchFeedbackRequest,
  ExtendedMatchFeedbackView,
  MatchQuality
} from "@/models/case-maintenance";
import { getModule } from "vuex-module-decorators";
import EcSnackBar from "@/components/common/ec-snackbar.vue";

import * as snackbarMessaging from "@/helpers/snackbarMessaging";
import AppState from "@/store/modules/app-module";
import Card from "@/components/material/Card.vue";
import MatchService from "@/services/match-service";
import MatchTable from "@/components/matches/match-table.vue";
import SessionState from "@/store/modules/session-state-module";
import { HalPagedResponse, PaginationOptions } from "@/models/hal";
import axios, { CancelTokenSource } from "axios";
import { SnackbarOptions } from "@/models/form";
import { Filter, FilterField } from "@/models/filters";
import FilterService from "@/services/filter-service";
import { MatchListOptions, Query } from "@/models/session-state-types";
import Debounce from "@/helpers/debounce";
import { Dictionary } from "vue-router/types/router";
import { queryHasChanged } from "@/components/helper/QueryKeyHelper";
const appState = getModule(AppState);
const sessionState = getModule(SessionState);

@Component({
  components: {
    Card,
    MatchTable
  }
})
export default class MatchesView extends Vue {
  @Inject() MatchService!: MatchService;
  @Inject() FilterService!: FilterService;

  filterFields: FilterField[] = [];
  filters: Filter[] = [];
  matches: ExtendedMatchFeedbackView[] = [];
  loading = true;
  page = 1;
  size = sessionState.defaultPageSize;
  totalItems = 0;
  cancelToken?: CancelTokenSource;
  sort: string[] = ["extendedMatchText, desc"];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();
  matchesRecord: Record<string, { score: MatchQuality; comments: string }> = {};

  statusErrors = {};
  assignmentErrors = {};
  get color() {
    return appState.apiFault ? "error" : "primary";
  }

  async mounted() {
    this.filterFields = await this.FilterService.getMatchesFilterDetails();
    await this.loadPage();
  }

  @Watch("page", { immediate: true })
  onPageChange(newPage: string) {
    this.$router.replace({ query: { ...this.$route.query, page: newPage } }).catch(() => true);
  }

  @Watch("$route", { immediate: true })
  async onRouteChange() {
    const { page = "1", size = "20", sort = ["created-at,desc"], filter } = this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = [sort as string[]].flat();

    const filtersParsed = MatchesView.parseFilterQueryString(filter);
    if (JSON.stringify(this.filters) != JSON.stringify(filtersParsed)) {
      this.filters = filtersParsed;
    }

    const matchListOptions = {
      options: {
        page: this.page,
        size: this.size,
        sort: this.sort
      } as PaginationOptions,
      filter: this.filters,
      query: this.$route.query as Query
    } as MatchListOptions;
    sessionState.setMatchListOptions(matchListOptions);

    await this.loadPage();
  }

  @Watch("filters", { immediate: true, deep: true })
  public insertBlankItem() {
    if (this.filters.length === 0) {
      this.filters.push({ key: null, value: "" });
      return;
    }

    const lastFilter = this.filters[this.filters.length - 1];
    if (lastFilter.key != null || lastFilter.value !== "") {
      this.filters.push({ key: null, value: "" });
    }
  }

  @Watch("filters", { deep: true })
  @Debounce(500)
  public filtersChanged(): void {
    const filterConditions = this.filters.reduce((q, f) => {
      if (f.key) {
        q.push(`${f.key}=${f.value}`);
      }
      return q;
    }, [] as string[]);

    const filter = filterConditions.length ? filterConditions.join(",") : [];

    const currentQuery = this.$route.query;
    const newQuery: Dictionary<string | (string | null)[]> = {
      ...currentQuery,
      filter
    };

    if (queryHasChanged(newQuery, this.$route.query)) {
      newQuery.page = "1";
      this.$router.replace({ query: newQuery }).catch(() => true);
    }
  }

  // Filter fields are loaded asynchronously as they need library lists.
  // In order for filters such as date-range to work correctly after an
  // F5-event, we need to reload the cases page after the fields are
  // available.
  @Watch("filterFields") onFilterFieldsChanged() {
    this.loadPage();
  }

  static parseFilterQueryString(query: string | (string | null)[]): Filter[] {
    if (query && typeof query === "string") {
      return query
        .split(",")
        .map(x => x.split("="))
        .map(x => ({ key: x[0], value: x[1] } as Filter))
        .concat([{ key: null, value: "" }]);
    }

    return [{ key: null, value: "" }];
  }

  async loadPage() {
    this.loading = true;

    this.statusErrors = {};
    this.assignmentErrors = {};
    this.matches = [];

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort
    };

    try {
      if (this.cancelToken) this.cancelToken.cancel();
      this.cancelToken = axios.CancelToken.source();

      let list: HalPagedResponse<ExtendedMatchFeedbackView, "matches"> = {
        page: {
          number: 1,
          size: 0,
          totalElements: 0
        },
        _embedded: { matches: [] }
      };
      try {
        list = await this.MatchService.listMatches(
          pagination,
          this.filters,
          this.filterFields,
          this.cancelToken.token
        );
      } catch {
        this.loading = false;
        snackbarMessaging.setUnsuccesfulMessage("Couldn't retrieve matches.");
        return;
      }

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.matches = list._embedded.matches;

      sessionState.setCaseListPage(list.page);
      sessionState.setDefaultPageSize(this.size);

      this.loading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
        this.loading = false;
      }
    }
  }

  async sendMultiMatchFeedback() {
    const mappedFeedback: ExtendedMatchFeedbackRequest[] = Object.keys(this.matchesRecord).map(
      id => {
        return { extendedMatchId: id, ...this.matchesRecord[id] };
      }
    );

    try {
      await this.MatchService.SendFeedback(mappedFeedback);
      snackbarMessaging.setSuccessMessage("Feedback Submitted");
    } catch {
      snackbarMessaging.setUnsuccesfulMessage("Something went wrong submitting feedback.");
    } finally {
      await this.loadPage();
      this.page = 1;
    }
  }
}
