var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-filter',{attrs:{"filters":_vm.syncedFilters,"filter-fields":_vm.filterFields},on:{"update:filters":function($event){_vm.syncedFilters=$event}}}),_c('v-data-table',_vm._g(_vm._b({staticClass:"case-table elevation-2 mt-2",attrs:{"headers":_vm.headers,"dense":"","item-key":"id","items-per-page":_vm.syncedItemsPerPage,"items":_vm.items,"loading":_vm.loading,"page":_vm.syncedPage,"server-items-length":_vm.serverItemsLength,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.extendedText",fn:function(ref){
var item = ref.item;
return [_c('span',{},[_vm._v(" ..."),_c('highlight',{attrs:{"value":item.extendedMatchText,"suppliedTokens":_vm.highlightMatchesInExtendedText(item.extendedText, item.text)}}),_vm._v("... ")],1)]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.libraryName)+" : "+_vm._s(item.ruleName))])]}},{key:"item.quality",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('MatchQualitySelector',_vm._g(_vm._b({attrs:{"value":_vm.getMatchValue(item.id),"held-down-button-value":_vm.heldDownButtonValue},on:{"input":function($event){return _vm.setMatchValue(item.id, $event)},"button-mouse-down":_vm.onButtonMouseDown,"button-mouse-up":_vm.onButtonMouseUp}},'MatchQualitySelector',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" Hint: Try clicking and dragging down ")])])]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticStyle:{"min-width":"15ch"},attrs:{"value":_vm.getMatchComments(item.id),"hide-details":"","single-line":"","underlined":""},on:{"input":function($event){return _vm.setMatchComments(item.id, $event)}}})]}},{key:"footer",fn:function(){return undefined},proxy:true}]),model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('div',{staticClass:"mt-2",staticStyle:{"position":"relative"}},[_c('v-pagination',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"length":_vm.numberOfPages,"total-visible":"10"},model:{value:(_vm.syncedPage),callback:function ($$v) {_vm.syncedPage=$$v},expression:"syncedPage"}}),_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"42px"}},[_c('span',{staticClass:"mx-2"},[_vm._v("Matches Per Page:")]),_c('div',{staticClass:"d-flex align-center "},[_c('v-select',{staticStyle:{"max-width":"140px"},attrs:{"id":"itemsPerPage","items":[10, 20, 50, 100],"hide-details":""},model:{value:(_vm.syncedItemsPerPage),callback:function ($$v) {_vm.syncedItemsPerPage=$$v},expression:"syncedItemsPerPage"}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.rowNumber(0))+"-"+_vm._s(_vm.rowNumber(_vm.items.length - 1))+" of "+_vm._s(_vm.serverItemsLength)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }