import { Dictionary } from "vue-router/types/router";

function queryValueHasChanged(
  newValue: string | (string | null)[],
  currentValue: string | (string | null)[]
): boolean {
  if (typeof newValue === "string" && typeof currentValue === "string") {
    return newValue !== currentValue;
  }

  if (typeof currentValue === "string") {
    currentValue = [currentValue];
  }

  return JSON.stringify(newValue) !== JSON.stringify(currentValue);
}

export function queryHasChanged(
  newQuery: Dictionary<string | (string | null)[]>,
  currentQuery: Dictionary<string | (string | null)[]>
): boolean {
  const newKeys = Object.keys(newQuery);
  const currentKeys = Object.keys(currentQuery);

  if (newKeys.length !== currentKeys.length) return true;

  for (const key of newKeys) {
    if (!(key in currentQuery)) return true;
    if (queryValueHasChanged(newQuery[key], currentQuery[key])) return true;
  }

  return false;
}
